import EventDashboard from "events/eventDashboard/EventDashboard";
 
 
function MissionsPage() {
  return (
    <>
    <h2>Missions</h2>
    <EventDashboard></EventDashboard>
    </>
  );
}

export default MissionsPage;
