import EventForm from 'events/eventForm/EventForm';
import React from 'react';
import {Grid } from 'semantic-ui-react';
import EventList from './EventList';


export default function EventDashboard(){
    return (
        <Grid>
            <Grid.Column width={10}>
                <EventList></EventList>
            </Grid.Column>
            <Grid.Column width={6}>
                <EventForm></EventForm> 
            </Grid.Column>
        </Grid>
    )
}