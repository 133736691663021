import EventDashboard from "events/eventDashboard/EventDashboard";
 
 

function TrainingsPage() {
 
  return (
    <>
     <h2>Trainings</h2>
     <EventDashboard></EventDashboard>
     </>
  );
}

export default TrainingsPage;
