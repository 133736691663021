import React from 'react';
import EventListItem from './EventListItem';

export default function EventList(){
    return (
        <>
        <EventListItem></EventListItem>
        <EventListItem></EventListItem>
        <EventListItem></EventListItem>
        <EventListItem></EventListItem>
        </>
    )
}