import { Item, Segment, Icon } from "semantic-ui-react";
import EventListAttendee from "./EventListAttendee";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Container,
  Col,
  Form,
  Glyphicon,
} from "react-bootstrap";

export default function EventListItem() {
  return (
    <>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-9">
              <h3>3/2 Training in the Glades </h3>

              <p>
                This training event will be at 9:30 in the glades training spot.
                This training event will be at 9:30 in the glades training
                spot.This training event will be at 9:30 in the glades training
                spot.This training event will be at 9:30 in the glades training
                spot.
              </p>
            </div>
            <div class="col-sm-3 text-right">
              <div>
                <span class="text-white bg-secondary p-2  rounded">
                  002334
                </span>
              </div>
              <div class="">&nbsp;</div>
              <div>IC: Tim Rynolds</div>
              <div>Training Lead: Stacy Brooks</div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-12">
              <div>
                <b>Members Responded</b>
                <div>
                  <EventListAttendee></EventListAttendee>
                </div>
              </div>
              <div>
                <hr />
                <span>
                  <Icon name="clock" />
                  3/14/2021 07:30 - 13:00 Eastern (6.5 hrs)<br/>
                  <Icon name="marker" />
                  1299 Fox Tails Chasing Lane, Windchester, Maine 33221. Off of
                  HWY 43 MM 32
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
