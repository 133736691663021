import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <ul className="footer-menu">
              <li>
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Home
                </a>
              </li>
              <li>
                <a href="https://www.sartoolbox.com" target="_Blank">
                  Blog
                </a>
              </li>
            </ul>
            <p className="copyright text-center">
              © <script>document.write(new Date().getFullYear())</script>
              <a href="https://www.sartoolbox.com">SARToolbox Inc.</a>, serving the ones who serve.
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
