import React from 'react';
import { Icon } from "semantic-ui-react";
export default function EventListAttendee(){
    return (
        <>
        
        <span class="mr-2"><Icon color="green" name="check circle outline" />Steve M.</span>
        <span class="mr-2"><Icon color="grey" name="circle outline" />Holmes T.</span>
        <span class="mr-2"><Icon color="red" name="times circle outline" />Bob R.</span>
        <span class="mr-2"><Icon color="red" name="times circle outline" />Stacy K.</span>
        </>
    )
}