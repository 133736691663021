import EventDashboard from 'events/eventDashboard/EventDashboard';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DECREMENT_COUNTER, INCREMENT_COUNTER } from './testReducer';

export default function Sandbox(){
    const dispatch = useDispatch();
    const data = useSelector(state => state.test.data);
    return (
        <>
            <h1>Testing 123</h1>
            <h3>Data is: {data}</h3>

            <button className="btn btn-primary m-1" content='Increment' onClick={()=> dispatch({type: INCREMENT_COUNTER})}>Increment</button>
            <button className="btn btn-primary m-1" content='Decrement' onClick={()=> dispatch({type: DECREMENT_COUNTER})}>Decrement</button>
       
            
        </>
    )
}