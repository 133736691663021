import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Container,
    Col,
    Form,
    Glyphicon,
    Group
  } from "react-bootstrap";

export default function EventForm() {
  return (
      <div>
          <h3>New Event</h3>
    <Form>
        <Form.Group controlId="EventForm.ControlInput1">
            <Form.Label>Event Name</Form.Label>
            <Form.Control type="text" placeholder=""  />
        </Form.Group>
        <Form.Group controlId="EventForm.ControlSelect1">
            <Form.Label>Initial Incident Commander</Form.Label>
            <Form.Control as="select">
            <option>Jim Bones (Tracker)</option>
            <option>Bob Evans (K9 HR/Area)</option>
            <option default>Stach McDonald (Semi-Tech)</option>
            <option>Ham Sam (Probation)</option>
            <option>Rodger Holmstead (Member)</option>
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="EventForm.ControlSelect2">
            <Form.Label>Training Lead</Form.Label>
            <Form.Control as="select">
            <option>Jim Bones (Tracker)</option>
            <option>Bob Evans (K9 HR/Area)</option>
            <option>Stach McDonald (Semi-Tech)</option>
            <option>Ham Sam (Probation)</option>
            <option selected>Rodger Holmstead (Member)</option>
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="EventForm.ControlSelect3">
            <Form.Label>Request Specific People (Mandatory or Highly Requested)</Form.Label>
            <Form.Control as="select" multiple>
            <option selected>Jim Bones (Tracker)</option>
            <option selected>Bob Evans (K9 HR/Area)</option>
            <option>Stach McDonald (Semi-Tech)</option>
            <option selected>Ham Sam (Probation)</option>
            <option>Rodger Holmstead (Member)</option>
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="EventForm.ControlTextarea1">
            <Form.Label>Description of Event</Form.Label>
            <Form.Control as="textarea" rows={3} />
        </Form.Group>
    </Form>
    </div>
  )
}
